exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-experiments-01-grid-hover-index-js": () => import("./../../../src/pages/experiments/01-grid-hover/index.js" /* webpackChunkName: "component---src-pages-experiments-01-grid-hover-index-js" */),
  "component---src-pages-experiments-index-js": () => import("./../../../src/pages/experiments/index.js" /* webpackChunkName: "component---src-pages-experiments-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

